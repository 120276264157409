import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

const verifyAccess = () => {
  const router = inject(Router);
  const messageService = inject(MessageService);
  messageService.add({ severity: 'error', summary: 'Page not found', detail: 'Redirected to Home page' });
  return router.createUrlTree(['/']);
};

export const notFoundGuardActivate: CanActivateFn = () => {
  return verifyAccess();
};
