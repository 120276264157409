import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

const verifyAccess = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return await Promise.resolve().then(() => {
    if (!authService.isLoggedIn()) {
      return true;
    }
    return router.createUrlTree(['/']);
  });
};

export const loginGuardActivate: CanActivateFn = () => {
  return verifyAccess();
};

export const loginGuardMatch: CanMatchFn = () => {
  return verifyAccess();
};
