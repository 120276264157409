import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, CanActivateFn, CanMatchFn } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';

const verifyAccess = (route: ActivatedRouteSnapshot | Route) => {
  const permissionsService = inject(PermissionsService);
  if (route instanceof ActivatedRouteSnapshot) {
    return verifyActivatedRoute(permissionsService, route);
  }
  return permissionsService.hasPermission(route.data?.permissions);
};

export const permissionsGuardActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  return verifyAccess(route);
};

export const permissionsGuardMatch: CanMatchFn = (route: Route) => {
  return verifyAccess(route);
};

const verifyActivatedRoute = (permissionsService: PermissionsService, route: ActivatedRouteSnapshot) => {
  if (!permissionsService.hasPermission(route.data.permissions)) {
    return false;
  }
  let parentRoute = route;
  while (parentRoute.parent) {
    parentRoute = parentRoute.parent;
    if (!permissionsService.hasPermission(parentRoute.data.permissions)) {
      return false;
    }
  }
  let childRoute = route;
  while (childRoute.firstChild) {
    childRoute = childRoute.firstChild;
    if (!permissionsService.hasPermission(childRoute.data.permissions)) {
      return false;
    }
  }
  return true;
};
