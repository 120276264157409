export const dataentryMcaStatuses = [
  '',
  'Rush',
  'ACH Soft Pre-App',
  'ACH Pre-Approved',
  'LB Pre-Approved',
  'Underwriter 4',
  'Cross Collateralize',
  'Underwriter 5',
  'Underwriting',
  'Previously Submitted',
  'Possible Decline',
  'Contract IN',
  'Contract Requested',
  'Contract OUT',
  'Missing Documents',
  'Documents Requested',
  'Requested Additional Info',
  'Underwriter 6',
  'Underwriter 3',
  'Requested Info/ Ad. Bal.',
  'Underwriter 2',
  'Rush - Completed',
  'Underwriter 1',
  'White Labeling',
  'Need to process',
  'Priority deals',
  'New',
  'Declined',
  'Merchant Declined',
  'UA Completed',
  'Waiting for Boris',
  'Processing',
  'Processing Completed',
  'Processing Completed (Priority)',
  'Processing Completed (Rush)',
  'Processing Completed (Regular)',
  'Processing UW (Bohdan P)',
  'Processing UW (Anastasiia S)',
  'Processing UW (Lena T)',
  'Processing UW (Ana F)',
  'Processing UW (Olha L)',
  'Processing UW (Sofiia Y)',
  'Processing UW (Andrii S)',
  'Processing UW (Anna K)',
  'Processing UW (Olga O)',
  'Processing UW (Olha L)',
  'Processing UW (Tania T)',
  'Processing UW (Oksana V)',
  'Processing UW (Liliana M)',
  'Processing UW (Anastasia V)',
  'Processing UW (Nataliia H)',
  'Processing UW (Yaryna S)',
  'Processing UW (Nelli K)',
  'Processing UW (Arsen B)',
  'Processing UW (Luisa O)',
  'Processing UW (Daniela C)',
  'Processing UW (Kostya Voytiv)',
  'Processing UW (Stanislav Antiukh)',
  'Processing UW (Anna Shlepina)',
  'Processing UW (Valeria Q)',
  'Processing UW (Andrea B)',
  'Queue for Default Program',
  'Deal Sorting (Priority)',
  'Merchant Unresponsive',
  'Pricing UW (Olya K)',
  'Pricing UW (Boris S)',
  'Pricing UW (Artem I)',
  'Pricing UW (Alina M)',
  'Pricing UW (Vika B)',
  'Pricing UW (Alla S)',
  'Pricing UW (Alex S)',
  'Processing UW (Vladyslav S)',
  'Deal Sorting (Regular)',
  'Deal Sorting (Rush)',
  'A.N.F. (Approved - Not - Funded)',

  'Funded NA',
  'Funded',
  'Funded-Other',
  'Funded Edit',
  'Completed',
  'Overpaied',

  'Data Processing (Stephanie A)',
  'Data Processing (Olena K)',
  'Data Processing (Oleksandr K)',
  'Data Processing (Mariia S)',
  'Data Processing (Kristina H)',
  'Data Processing (Isabella M)',
  'Data Processing (Natalia T)',
  'Data Processing (Iryna L)',
  'Data Processing (Nadiia Y)',

  'Ready for Calc',
  'Ready for Calc (Rush)',
  'Ready for Pricing',
  'Ready for Pricing (Rush)',
  'Processing UW - Calc Verification',
  'X - Delete Deal Request - X',
  'x - DELETED DEAL - x',
  //  @@bk
];
