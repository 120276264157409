import { MongoAddress } from '@mca/shared/domain';

export enum AccountReceivableCollectabilityRankLabels {
  'high' = 'High Collectability',
  'mid' = 'Moderate Collectability',
  'low' = 'Low Collectability',
  'none' = 'Not Collectable',
  'tbd' = 'Undetermined',
}
export type AccountReceivableCollectabilityRank = keyof typeof AccountReceivableCollectabilityRankLabels;

export const AccountReceivableCollectabilityRankDescriptions: Record<AccountReceivableCollectabilityRank, string> = {
  high: 'AR will place a hold or return funds within 1 week of the initial request',
  mid: 'AR will place a hold or return funds within 2-5 weeks of the initial request',
  low: 'AR is less responsive and it will take over 6+ weeks from the initial request to put a hold on funds',
  none: 'There is no feasible avenue for recovery from existing AR',
  tbd: `AR's that have not been yet assessed for their collectability status and require additional review`,
};

export enum AccountReceivableDistributionCriteriaLabels {
  'mail' = 'MAIL',
  'email' = 'EMAIL',
  'portal' = 'PORTAL',
  'mail_ct_corp' = 'MAIL via CT Corp',
  'tbd' = 'Undetermined',
}
export type AccountReceivableDistributionCriteria = keyof typeof AccountReceivableDistributionCriteriaLabels;

export class AccountReceivableData {
  id?: number;
  distribution_criteria = 'email' as AccountReceivableDistributionCriteria;
  industry? = [] as number[];
  is_active = true;
}

export class AccountReceivable extends AccountReceivableData {
  user_id = 0;
}

export enum AccountReceivableStatus {
  'Active AR' = 1,
  'Active AR (Funds/Account Frozen)',
  'Inactive AR (No Funds)',
}

export interface AccountReceivableUserWrite {
  id?: number;
  fullname: string;
  firstname: string;
  lastname: string;
  legalname: string;
  cellphone: string;
  emails: string[];
  industry: number[];
  address: MongoAddress;
  is_active: boolean;
  collectability_rank: AccountReceivableCollectabilityRank;
  distribution_criteria: AccountReceivableDistributionCriteria[];
}

export interface AccountReceivableUser extends AccountReceivableUserWrite {
  id: number;
}

export interface AccountReceivableUserAssigned extends AccountReceivableUser {
  status: AccountReceivableStatus;
  amount: number;
  note: string;
  update_timestamp: string;
  update_user: number;
}
