import { ActivatedRouteSnapshot } from '@angular/router';

export const getPathToRoute = (route: ActivatedRouteSnapshot, skipEmpty = false) => {
  let path = '';
  for (let r = route; r.parent; r = r.parent) {
    if (!r.url.length && skipEmpty) {
      continue;
    }
    path = r.url + (path ? '/' + path : '');
  }
  return path;
};
