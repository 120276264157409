import { AbstractControl } from '@angular/forms';

export const passwordMatch = (control: AbstractControl): any => {
  const passwordControl = control.get('password');
  const confirmControl = control.get('repeatpassword');

  if (passwordControl?.pristine || confirmControl?.pristine) {
    return null;
  }

  if (passwordControl?.value === confirmControl?.value) {
    return null;
  }

  confirmControl?.setErrors({ passwordMatch: true });

  return {
    passwordMatch: {
      valid: false,
    },
  };
};
