import { Injectable, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PermissionNames } from '../entities/permission';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class MenuAccessService {
  private permissionsService = inject(PermissionsService);

  private menuItems: MenuItem[] = [];
  private allowedMenuItems: MenuItem[] = [];

  setMenuItems(items: MenuItem[]) {
    this.menuItems = items;
    this.allowedMenuItems = this.menuItems.map(this.filterItems.bind(this)).filter((item): item is MenuItem => item !== false);
  }

  getAllowedItems() {
    return this.allowedMenuItems;
  }

  checkMenuRouteAccess(path: string): boolean {
    return !!this.searchByRoutePath(path, this.allowedMenuItems);
  }

  private trimSlashes(value: string) {
    return value.replace(/^\/+|\/+$/g, '');
  }

  private searchByRoutePath(routePath: string, items: MenuItem[]): MenuItem | undefined {
    const path = this.trimSlashes(routePath);
    return items.find(item => {
      const route = item.routerLink?.join('/') ?? '';
      const match = item.pathMatch === 'partial' ? route.indexOf(path) > -1 : path.split('/').some(v => route.indexOf(v) > -1);
      return match || (item.items && this.searchByRoutePath(path, item.items));
    });
  }

  filterItems(item: MenuItem): MenuItem | false {
    if (!this.verifyAccess(item)) {
      return false;
    }
    return {
      ...item,
      items: item.items
        ?.map(i => (Array.isArray(i) ? i.map(this.filterItems.bind(this)).filter(Boolean) : this.filterItems(i)))
        .filter(Boolean) as MenuItem[][],
    };
  }

  private verifyAccess(item: MenuItem) {
    return !item.permissions || this.permissionsService.hasPermission(item.permissions as PermissionNames[]);
  }
}
