import { withMemoization } from '../utils/memoization.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const shouldSendBody = method => !['HEAD', 'GET', 'DELETE'].includes(method.toUpperCase());
// TODO[AllanZhengYP]: we need to provide isCanceledError utility
const fetchTransferHandler = async ({
  url,
  method,
  headers,
  body
}, {
  abortSignal,
  cache,
  withCrossDomainCredentials
}) => {
  let resp;
  try {
    resp = await fetch(url, {
      method,
      headers,
      body: shouldSendBody(method) ? body : undefined,
      signal: abortSignal,
      cache,
      credentials: withCrossDomainCredentials ? 'include' : 'same-origin'
    });
  } catch (e) {
    // TODO: needs to revise error handling in v6
    // For now this is a thin wrapper over original fetch error similar to cognito-identity-js package.
    // Ref: https://github.com/aws-amplify/amplify-js/blob/4fbc8c0a2be7526aab723579b4c95b552195a80b/packages/amazon-cognito-identity-js/src/Client.js#L103-L108
    if (e instanceof TypeError) {
      throw new Error('Network error');
    }
    throw e;
  }
  const responseHeaders = {};
  resp.headers?.forEach((value, key) => {
    responseHeaders[key.toLowerCase()] = value;
  });
  const httpResponse = {
    statusCode: resp.status,
    headers: responseHeaders,
    body: null
  };
  // resp.body is a ReadableStream according to Fetch API spec, but React Native
  // does not implement it.
  const bodyWithMixin = Object.assign(resp.body ?? {}, {
    text: withMemoization(() => resp.text()),
    blob: withMemoization(() => resp.blob()),
    json: withMemoization(() => resp.json())
  });
  return {
    ...httpResponse,
    body: bodyWithMixin
  };
};
export { fetchTransferHandler };
