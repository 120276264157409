export class UserDefaults {
  displaydba = true;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  default_investor = false;
  dealvenue = 0;
  mcacommissions = 0;
  conscommissions = 0;
  upfrontfee = 0;
  rtrfee = 0;
  comissionstrategy = 0;
  withdrawalday = 0;
  defparticipation = 0;
  interninvestacct = false;
  depositstrategy?: string;
  legalname?: string;
  ein?: string;
  qb_name?: string;
  collateral_tags?: string[];

  constructor(public userid: number) {}
}
