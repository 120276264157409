import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, CanActivateFn, CanMatchFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

let urlBeforeAuth = '';
const verifyAccess = (routeOrSegments: ActivatedRouteSnapshot | UrlSegment[], routerState?: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isLoggedIn()) {
    urlBeforeAuth =
      routeOrSegments instanceof ActivatedRouteSnapshot
        ? routerState?.url ?? ''
        : router.createUrlTree(routeOrSegments.map(v => v.path)).toString();
    authService.logout();
    return false;
  }
  if (urlBeforeAuth) {
    router.navigateByUrl(urlBeforeAuth);
    urlBeforeAuth = '';
    return false;
  }
  return true;
};

export const authGuardActivate: CanActivateFn = (route: ActivatedRouteSnapshot | UrlSegment[], routerState?: RouterStateSnapshot) => {
  return verifyAccess(route, routerState);
};

export const authGuardMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return verifyAccess(segments);
};
