import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { distinctUntilChanged, map } from 'rxjs';
import { PermissionNames } from '../entities/permission';
import { PermissionsService } from '../services/permissions.service';

interface State {
  canView?: PermissionNames[] | PermissionNames;
  canViewElse: TemplateRef<any>;
}

@Directive({
  selector: '[libAuthDomainCanView]',
  providers: [RxState],
  standalone: true,
})
export class CanViewDirective implements OnInit {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private permissionService = inject(PermissionsService);
  private state = inject<RxState<State>>(RxState);

  @Input()
  set libAuthDomainCanView(canView: PermissionNames[] | PermissionNames | undefined) {
    this.state.set({ canView });
  }
  @Input()
  set libAuthDomainCanViewElse(canViewElse: TemplateRef<any>) {
    this.state.set({ canViewElse });
  }

  ngOnInit(): void {
    this.state.hold(
      this.state.select().pipe(
        map(state => {
          if (!state.canView) {
            return undefined;
          }
          return this.permissionService.hasPermission(state.canView);
        }),
        distinctUntilChanged(),
      ),
      hasPermission => {
        this.viewContainer.clear();
        if (hasPermission || hasPermission === undefined) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.libAuthDomainCanViewElse) {
          this.viewContainer.createEmbeddedView(this.libAuthDomainCanViewElse);
        }
      },
    );
  }
}
