import { Injectable } from '@angular/core';
import { UserLoginInfo } from '@mca/user/api';
import { PermissionNames } from '../entities/permission';

type PermissionsFilter = (user?: UserLoginInfo) => boolean;
type PermissionsFilters = Record<PermissionNames, Record<string, PermissionsFilter>>;

@Injectable({
  providedIn: 'root',
})
export class PermissionsFilterService {
  private permissionsFilters = {} as PermissionsFilters;

  setFilter(scope: string, permissions: PermissionNames[], filter: PermissionsFilter) {
    permissions.forEach(permission => {
      this.permissionsFilters[permission] ??= {};
      this.permissionsFilters[permission][scope] = filter;
    });
  }

  unsetFilters(scope: string, permissions?: PermissionNames[]) {
    permissions ??= Object.keys(this.permissionsFilters) as PermissionNames[];
    permissions.forEach(permission => {
      if (this.permissionsFilters[permission]) {
        delete this.permissionsFilters[permission][scope];
      }
    });
  }

  getFilters(permission: PermissionNames) {
    return Object.values(this.permissionsFilters[permission] ?? {});
  }

  filterUserPermissions(user: UserLoginInfo, permissions: PermissionNames[]) {
    return permissions.filter(permission => !this.getFilters(permission).some(filter => !filter(user)));
  }
}
