import { Injectable, NgZone, inject } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  from,
  merge,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap,
  Observable,
} from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { httpAuthLoginInfo, httpAuthLogout, httpUserConfirm } from '../infrastructure/auth-http-points';
import { UserReferenceService, UserLoginInfo } from '@mca/user/api';
import { ApiService } from '@mca/shared/util';
import * as Sentry from '@sentry/angular-ivy';
import { AuthUser, fetchAuthSession } from 'aws-amplify/auth';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { DBARec, SystemConfig } from '@mca/shared/domain';
import { ServiceWorkerCommand, serviceWorkerClientUrlToken } from '@mca/shared/util/service-worker';

interface State {
  currentUser: UserLoginInfo | null;
  awsUser?: AuthUser | null;
  awsToken: string | null;
  systemConfig: SystemConfig | null;
}

interface LoginInfo extends UserLoginInfo {
  system_config: SystemConfig;
}

const AuthParamStatus = {
  NotInitialized: undefined,
  SignedOut: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private amplifyAuth = inject(AuthenticatorService);
  private apiService = inject(ApiService);
  private usersService = inject(UserReferenceService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private ngZone = inject(NgZone);
  private serviceWorkerClientUrl = inject(serviceWorkerClientUrlToken);

  readonly state$ = new BehaviorSubject({ currentUser: AuthParamStatus.SignedOut, awsUser: AuthParamStatus.NotInitialized } as State);

  currentUser$ = this.getStateDistinctValue<LoginInfo>('currentUser');
  get currentUser(): UserLoginInfo | null {
    return this.state$.value.currentUser;
  }
  get currentUserLoaded$() {
    return this.getStateDistinctValue<LoginInfo>('currentUser').pipe(
      filter(user => !!user),
      first(),
    );
  }
  get systemConfig() {
    return this.state$.value.systemConfig;
  }
  get token() {
    return this.state$.value.awsToken;
  }
  get tokenLoaded$() {
    return this.getStateDistinctValue('awsToken').pipe(
      filter(token => token !== AuthParamStatus.NotInitialized),
      first(),
    );
  }

  private token$ = this.getStateDistinctValue('awsUser').pipe(
    filter(Boolean),
    switchMap(() =>
      from(fetchAuthSession()).pipe(
        catchError(() => EMPTY),
        map(session => session.tokens?.accessToken.toString()),
        filter(Boolean),
      ),
    ),
  );

  private userInfo$ = this.getStateDistinctValue('awsToken').pipe(
    filter(Boolean),
    switchMap(() =>
      this.apiService.get<LoginInfo>(httpAuthLoginInfo()).pipe(
        first(),
        catchError(() => {
          this.logout();
          return EMPTY;
        }),
      ),
    ),
    // set user only when roles are updated and we can map role names to ids
    switchMap(loginInfo =>
      this.usersService.reloadRoles().pipe(
        first(),
        map(() => {
          const { system_config: systemConfig, ...currentUser } = loginInfo;
          return { systemConfig, currentUser };
        }),
      ),
    ),
    tap(({ currentUser }) => {
      Sentry.setUser({ id: currentUser.userid, username: currentUser.person?.fullname });
      this.ngZone.run(() => {
        if (this.router.url === '/login') {
          this.router.navigate(['/']);
        }
      });
    }),
  );

  constructor() {
    this.restoreSession();
    this.watchAWSState();

    this.getStateDistinctValue('awsToken')
      .pipe(filter(() => this.router.url === '/app/login'))
      .subscribe(() =>
        this.ngZone.run(() => {
          this.router.navigate(['/app']);
        }),
      );
    this.getStateDistinctValue<LoginInfo>('currentUser')
      .pipe(filter(currentUser => currentUser && this.router.url === '/login'))
      .subscribe(() =>
        this.ngZone.run(() => {
          this.router.navigate(['/']);
        }),
      );
    merge(this.token$.pipe(map(awsToken => ({ awsToken }))), this.userInfo$).subscribe(partialState =>
      this.state$.next({ ...this.state$.value, ...partialState }),
    );
  }

  getStateDistinctValue<T = State[keyof State]>(value: keyof State): Observable<T> {
    return this.state$.pipe(
      map(state => state[value] as T),
      distinctUntilChanged(),
    );
  }

  private restoreSession() {
    fetchAuthSession()
      .then(session => session.tokens?.accessToken.toString() ?? AuthParamStatus.SignedOut)
      .catch(() => AuthParamStatus.SignedOut)
      .then(awsToken => this.state$.next({ ...this.state$.value, awsUser: this.amplifyAuth.user, awsToken }));
  }

  private watchAWSState() {
    Hub.listen('auth', data => {
      if (data.payload.event === 'signedIn') {
        this.restoreSession();
      }
    });
  }

  private cleanServiceWorkerCache() {
    // service workers only available with https
    const nvgtr = navigator as Navigator;
    if ('serviceWorker' in nvgtr) {
      nvgtr.serviceWorker.getRegistration(this.serviceWorkerClientUrl).then((registration?: ServiceWorkerRegistration) => {
        registration?.active?.postMessage({ command: ServiceWorkerCommand.CLEAN_CACHE });
      });
    }
  }

  logout() {
    this.messageService.add({ severity: 'error', summary: 'Session ended' });
    this.apiService.get(httpAuthLogout()).subscribe();
    this.cleanSession();
    this.router.navigate(['/login']);
  }

  confirmUser(tocken: string, password: string) {
    return this.apiService.post(httpUserConfirm(tocken), { pwd: password });
  }

  cleanSession() {
    this.amplifyAuth.signOut();
    this.cleanServiceWorkerCache();
    this.state$.next({
      currentUser: AuthParamStatus.SignedOut,
      awsToken: AuthParamStatus.SignedOut,
      awsUser: AuthParamStatus.SignedOut,
      systemConfig: AuthParamStatus.SignedOut,
    });
  }

  isLoggedIn() {
    return !!this.state$.value.awsToken;
  }

  getFixedFee(dba: DBARec) {
    return DBARec.hasFixedFee(dba) ? +(this.systemConfig?.fixedFee ?? 0) : 0;
  }
}
