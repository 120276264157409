import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {
  httpPathUserBankRec,
  httpPathUser,
  httpPathUserDefaults,
  httpPathUserISO,
  httpPathUserISOUpd,
  httpPathUserLogin,
  httpPathUserNames,
  httpPathUserCommUserInfo,
  httpPathUserBankRecUpdate,
  httpPathUsers,
  httpUsersCommInfo,
  httpSystemLoginActivity,
  httpUserDoc,
  httpUserDocList,
  httpVenueCommissionUsers,
  httpARUsers,
  httpARUserInfo,
  httpUserByRole,
} from '../infrastructure/user-http-endpoints';
import { catchError, map, take } from 'rxjs/operators';
import { UserDefaults } from '../entities/user-defaults';
import { Bankrec } from '../entities/bankrec';
import { Person } from '../entities/person';
import { UserLoginInfo } from '../entities/user-login-info';
import { ApiService } from '@mca/shared/util';
import { RxState } from '@rx-angular/state';
import { UserDoc } from '../entities/user-doc';
import {
  AccountReceivable,
  AccountReceivableData,
  AccountReceivableUser,
  AccountReceivableUserWrite,
} from '../entities/user-account-receivable';
import { toSignal } from '@angular/core/rxjs-interop';

export interface UserName {
  id: number;
  name: string;
  roles: number[];
}

export enum UserStatus {
  Active = 1,
  Inactive = 2,
  Confirmed = 3,
  Locked = 4,
}

interface State {
  nameMap: Record<number, UserName>;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends RxState<State> {
  private apiService = inject(ApiService);

  userNames = toSignal(
    this.getCache().pipe(
      map(users => users.reduce((acc, user) => Object.assign(acc, { [user.id]: user.name }), {} as Record<number, string>)),
    ),
    {
      initialValue: {} as Record<number, string>,
    },
  );
  userOptions = toSignal(this.getCache().pipe(map(users => users.map(user => ({ value: user.id, label: user.name })))), {
    initialValue: [],
  });
  userNameMap = toSignal(this.getCachedMap(), { initialValue: {} as Record<number, UserName> });

  constructor() {
    super();
    this.connect(
      'nameMap',
      this.apiService.get(httpPathUserNames()).pipe(
        map((userNames: UserName[]) => userNames.reduce((acc, v) => Object.assign(acc, { [v.id]: v }), {})),
        catchError(() => EMPTY),
      ),
    );
  }

  getCache(): Observable<UserName[]> {
    return this.select('nameMap').pipe(
      map(nameMap => Object.values(nameMap)),
      take(1),
    );
  }

  getCachedMap(): Observable<Record<number, UserName>> {
    return this.select('nameMap');
  }

  getPerson(id: number): Observable<Person> {
    return this.apiService.get(httpPathUser(id));
  }

  createPerson(user: Person) {
    return this.apiService.put(httpPathUsers(), user);
  }

  updatePerson(id: number, user: Person) {
    return this.apiService.post(httpPathUser(id), user);
  }

  getPersonBankrec(id: number): Observable<Bankrec> {
    return this.apiService.get(httpPathUserBankRec(id));
  }

  createPersonBankrec(uid: number, rec: Bankrec) {
    return this.apiService.post(httpPathUserBankRec(uid), rec);
  }

  updatePersonBankrec(id: number, rec: Bankrec) {
    if (rec.id > 0) {
      return this.apiService.put(httpPathUserBankRecUpdate(id, rec.id), rec);
    }
    return this.createPersonBankrec(id, rec);
  }

  getPersonFinDefaults(id: number): Observable<UserDefaults> {
    return this.apiService.get(httpPathUserDefaults(id));
  }

  // @todo deprecated?
  createPersonFinDefaults(id: number, rec: UserDefaults) {
    return this.apiService.put(httpPathUserDefaults(id), rec);
  }

  updatePersonFinDefaults(id: number, rec: UserDefaults) {
    return this.apiService.post(httpPathUserDefaults(id), rec);
  }

  // ISO relationship. Later multiple relationships with relationship type has to be added
  getISO(id: number) {
    return this.apiService.get(httpPathUserISO(id));
  }

  updateISO(id: number, relId: number) {
    return this.apiService.post(httpPathUserISOUpd(id, relId));
  }

  getLogin(id: number): Observable<UserLoginInfo> {
    return this.apiService.get(httpPathUserLogin(id));
  }

  updateLogin(id: number, user: UserLoginInfo) {
    return this.apiService.post(httpPathUserLogin(id), user);
  }

  getVenueCommissionUsers(venueId: number) {
    return this.apiService.get<number[]>(httpVenueCommissionUsers(venueId));
  }

  commissionUserInfo(id: number) {
    return this.apiService.get(httpPathUserCommUserInfo(id));
  }

  getDefaultInvestors() {
    return this.apiService.get(httpUsersCommInfo());
  }

  getLoginActivity(params: { userName: string; startDate: string | null; endDate: string | null }) {
    return this.apiService.get(httpSystemLoginActivity(), { params });
  }

  getNameByID(id: number) {
    return this.get('nameMap')[id].name;
  }

  getUserDocs(userid: number) {
    return this.apiService
      .get<UserDoc[]>(httpUserDocList(userid))
      .pipe(map(docs => docs.map(doc => ({ ...doc, category: doc.categoryid ? +doc.categoryid : 0 }))));
  }

  updateUserDoc(docid: number, data: Partial<UserDoc>) {
    return this.apiService.put(httpUserDoc(docid), data);
  }

  deleteUserDoc(id: number) {
    return this.apiService.delete(httpUserDoc(id));
  }

  getAccountReceivableUsers(
    params?: Partial<{
      industry: number[];
    }>,
  ) {
    return this.apiService.get<AccountReceivableUser[]>(httpARUsers(), { params });
  }

  createAccountReceivableUser(data: AccountReceivableUserWrite) {
    return this.apiService.post(httpARUsers(), data);
  }

  updateAccountReceivableUser(userid: number, data: Partial<AccountReceivableUserWrite>) {
    return this.apiService.put(httpARUsers(userid), data);
  }

  getAccountReceivableInfo(userid: number) {
    return this.apiService.get<AccountReceivable>(httpARUserInfo(userid));
  }

  createAccountReceivableInfo(userid: number, data: AccountReceivableData) {
    return this.apiService.post(httpARUserInfo(userid), data);
  }

  updateAccountReceivableInfo(userid: number, data: AccountReceivableData) {
    return this.apiService.put(httpARUserInfo(userid), data);
  }

  createUserByRole(roleId: number, data: Partial<Person>) {
    return this.apiService.post(httpUserByRole(roleId), data);
  }
}
