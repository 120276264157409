import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { AuthValidationErrorCode } from '../../../errors/types/validation.mjs';
import { assertValidationError } from '../../../errors/utils/assertValidationError.mjs';
import { verifyUserAttribute } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { assertAuthTokens } from '../utils/types.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Confirms a user attribute with the confirmation code.
 *
 * @param input -  The ConfirmUserAttributeInput object
 * @throws  -{@link AuthValidationErrorCode } -
 * Thrown when `confirmationCode` is not defined.
 * @throws  -{@link VerifyUserAttributeException } - Thrown due to an invalid confirmation code or attribute.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function confirmUserAttribute(input) {
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(authConfig);
  const {
    confirmationCode,
    userAttributeKey
  } = input;
  assertValidationError(!!confirmationCode, AuthValidationErrorCode.EmptyConfirmUserAttributeCode);
  const {
    tokens
  } = await fetchAuthSession({
    forceRefresh: false
  });
  assertAuthTokens(tokens);
  await verifyUserAttribute({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.ConfirmUserAttribute)
  }, {
    AccessToken: tokens.accessToken.toString(),
    AttributeName: userAttributeKey,
    Code: confirmationCode
  });
}
export { confirmUserAttribute };
