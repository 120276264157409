export const httpPathUsers = () => 'user';
export const httpPathUser = (id: number) => `user/${id}`;
export const httpPathUserBankRec = (id: number) => `user/${id}/bankrec`;
export const httpPathUserBankRecUpdate = (uid: number, recid: number) => `user/${uid}/bankrec/${recid}`;
export const httpPathUserDefaults = (id: number) => `user/${id}/findefaults`;
export const httpPathUserISO = (id: number) => `user/${id}/relationship`;
export const httpPathUserISOUpd = (id: number, relId: number) => `user/${id}/relationship/${relId}`;
export const httpPathRoles = () => 'user/roles';
export const httpPathRole = (id: number) => `user/roles/${id}`;
export const httpPathUserLogin = (id: number) => `user/${id}/login`;
export const httpPathUserNames = () => `user/names`;
export const httpPathUserCommUserInfo = (id: number) => `user/${id}/commuserinfo`;
export const httpUsersCommInfo = () => `user/defaultinvestors`;
export const httpSystemLoginActivity = () => `user/log/auth`;
export const httpUserDocList = (userid: number) => `user/${userid}/docs`;
export const httpUserDoc = (id: number) => (id ? `user/doc/${id}` : `user/doc`);
export const httpVenueCommissionUsers = (venueId: number) => `user/defaultcommusers/${venueId}`;
export const httpARUsers = (id?: number) => (id ? `ar/users/${id}` : 'ar/users');
export const httpARUserInfo = (userid: number) => `users/${userid}/ar`;
export const httpUserByRole = (roleId: number) => `user/role/${roleId}`;
