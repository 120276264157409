import { MongoAddress } from '@mca/shared/domain';

export class Person {
  id = 0;
  firstname?: string;
  lastname?: string;
  legalname?: string;
  fullname?: string;
  dob?: Date;

  cellphone?: string;
  phone?: string;
  extension?: string;
  emails?: string[] = [];
  commission_emails?: string[] = [];
  email?: string;

  ssn?: string;
  drivlic?: string;

  address = new MongoAddress();
}

export const getPersonName = (person: Person | undefined) => {
  if (!person) {
    return '';
  }
  if (person.fullname) {
    return person.fullname;
  }
  let name = '';
  if (person.firstname) {
    name += person.firstname;
  }
  if (person.lastname) {
    name += ' ';
    name += person.lastname;
  }

  return name;
};
