const svDict = {
  'Account recovery requires verified contact information': 'För att återställa kontot behöver du ett verifierat konto',
  'Back to Sign In': 'Tillbaka till inloggningen',
  'Change Password': 'Byt lösenord',
  Changing: 'Ändra',
  Code: 'Kod',
  'Confirm Password': 'Bekräfta lösenord',
  'Confirm Sign Up': 'Bekräfta registrering',
  'Confirm SMS Code': 'Bekräfta SMS-kod',
  'Confirm TOTP Code': 'Bekräfta TOTP-kod',
  Confirm: 'Bekräfta',
  'Confirmation Code': 'Verifikationskod',
  Confirming: 'Bekräftar',
  'Create a new account': 'Skapa ett nytt konto',
  'Create Account': 'Skapa konto',
  'Creating Account': 'Skapar konto',
  'Dismiss alert': 'Avvisa varning',
  Email: 'E-post',
  'Enter your code': 'Skriv din kod',
  'Enter your Email': 'Fyll i din e-post',
  'Enter your phone number': 'Ange ditt telefonnummer',
  'Enter your username': 'Ange ditt användarnamn',
  'Forgot your password?': 'Glömt ditt lösenord? ',
  'Hide password': 'Dölj lösenord',
  'It may take a minute to arrive': 'Det kan ta en minut att komma fram',
  Loading: 'Laddar',
  'New password': 'Nytt lösenord',
  or: 'eller',
  Password: 'Lösenord',
  'Phone Number': 'Telefonnummer',
  'Resend Code': 'Skicka koden igen',
  'Reset your password': 'Återställ ditt lösenord',
  'Reset your Password': 'Återställ ditt lösenord',
  'Send code': 'Skicka kod',
  'Send Code': 'Skicka kod',
  Sending: 'Skickar',
  'Setup TOTP': 'Konfigurera TOTP',
  'Show password': 'Visa lösenord',
  'Sign in to your account': 'Logga in till ditt konto',
  'Sign In with Amazon': 'Logga in med Amazon',
  'Sign In with Apple': 'Logga in med Apple',
  'Sign In with Facebook': 'Logga in med Facebook',
  'Sign In with Google': 'Logga in med Google',
  'Sign in': 'Logga in',
  'Sign In': 'Logga in',
  'Signing in': 'Loggar in',
  Skip: 'Hoppa över',
  Submit: 'Skicka',
  Submitting: 'Skickar in',
  Username: 'Användarnamn',
  'Verify Contact': 'Verifiera kontakt',
  Verify: 'Verifiera',
  'We Sent A Code': 'Vi skickade en kod',
  'We Texted You': 'Vi sms:ade dig',
  'Your code is on the way. To log in, enter the code we emailed to': 'Din kod är på väg. För att logga in, ange koden vi mejlade till',
  'Your code is on the way. To log in, enter the code we sent you': 'Din kod är på väg. För att logga in, ange koden vi skickade till dig',
  'Your code is on the way. To log in, enter the code we texted to': 'Din kod är på väg. För att logga in, ange koden vi sms:ade till',
  // Additional translations provided by customers
  'An account with the given email already exists.': 'Det finns redan ett konto med denna e-postadress',
  'Confirm a Code': 'Bekräfta koden',
  'Confirm Sign In': 'Bekräfta inloggning',
  'Create account': 'Skapa konto',
  'Enter your password': 'Ange ditt lösenord',
  'Forgot Password': 'Glömt lösenordet',
  'Have an account? ': 'Redan registrerad? ',
  'Incorrect username or password': 'Felaktigt användarnamn eller lösenord',
  'Invalid password format': 'Ogiltigt lösenordsformat',
  'Invalid phone number format': `Ogiltigt format för telefonnummer`,
  'Lost your code? ': 'Förlorat koden? ',
  'New Password': 'Nytt lösenord',
  'No account? ': 'Inget konto? ',
  'Password attempts exceeded': 'Maximalt antal felaktiga inloggningsförsök har uppnåtts',
  'Reset password': 'Återställ lösenord',
  'Sign Out': 'Logga ut',
  'Sign Up': 'Registrering',
  'User already exists': 'Användaren finns redan',
  'User does not exist': 'Användaren finns inte',
  'Username cannot be empty': 'Användarnamnet kan inte vara tomt',
  'We Emailed You': 'Vi har skickat e-post till dig'
};
export { svDict };