import { Person } from './person';

export class UserLoginInfo {
  id = 0;
  userid = '';
  roles: number[] = [];
  permissions?: string[];
  status = 2;
  token?: string;
  person?: Person;
}
