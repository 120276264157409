import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { getUserAttributeVerificationCode } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { assertAuthTokens } from '../utils/types.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Resends user's confirmation code when updating attributes while authenticated.
 *
 * @param input - The SendUserAttributeVerificationCodeInput object
 * @returns SendUserAttributeVerificationCodeOutput
 * @throws - {@link GetUserAttributeVerificationException}
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
const sendUserAttributeVerificationCode = async input => {
  const {
    userAttributeKey,
    options
  } = input;
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  const clientMetadata = options?.clientMetadata;
  assertTokenProviderConfig(authConfig);
  const {
    tokens
  } = await fetchAuthSession({
    forceRefresh: false
  });
  assertAuthTokens(tokens);
  const {
    CodeDeliveryDetails
  } = await getUserAttributeVerificationCode({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.SendUserAttributeVerificationCode)
  }, {
    AccessToken: tokens.accessToken.toString(),
    ClientMetadata: clientMetadata,
    AttributeName: userAttributeKey
  });
  const {
    DeliveryMedium,
    AttributeName,
    Destination
  } = {
    ...CodeDeliveryDetails
  };
  return {
    destination: Destination,
    deliveryMedium: DeliveryMedium,
    attributeName: AttributeName
  };
};
export { sendUserAttributeVerificationCode };
