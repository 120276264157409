import { Amplify } from '@aws-amplify/core';
import { unauthenticatedHandler, getRetryDecider, parseJsonError, jitteredBackoff, getDnsSuffix } from '@aws-amplify/core/internals/aws-client-utils';
import { getAmplifyUserAgent, AmplifyUrl } from '@aws-amplify/core/internals/utils';
import { composeTransferHandler } from '@aws-amplify/core/internals/aws-client-utils/composers';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * The service name used to sign requests if the API requires authentication.
 */
const SERVICE_NAME = 'cognito-idp';
/**
 * The endpoint resolver function that returns the endpoint URL for a given region.
 */
const endpointResolver = ({
  region
}) => {
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  const customURL = authConfig?.userPoolEndpoint;
  const defaultURL = new AmplifyUrl(`https://${SERVICE_NAME}.${region}.${getDnsSuffix(region)}`);
  return {
    url: customURL ? new AmplifyUrl(customURL) : defaultURL
  };
};
/**
 * A Cognito Identity-specific middleware that disables caching for all requests.
 */
const disableCacheMiddlewareFactory = () => (next, _) => async function disableCacheMiddleware(request) {
  request.headers['cache-control'] = 'no-store';
  return next(request);
};
/**
 * A Cognito Identity-specific transfer handler that does NOT sign requests, and
 * disables caching.
 *
 * @internal
 */
const cognitoUserPoolTransferHandler = composeTransferHandler(unauthenticatedHandler, [disableCacheMiddlewareFactory]);
/**
 * @internal
 */
const defaultConfig = {
  service: SERVICE_NAME,
  endpointResolver,
  retryDecider: getRetryDecider(parseJsonError),
  computeDelay: jitteredBackoff,
  userAgentValue: getAmplifyUserAgent(),
  cache: 'no-store'
};
/**
 * @internal
 */
const getSharedHeaders = operation => ({
  'content-type': 'application/x-amz-json-1.1',
  'x-amz-target': `AWSCognitoIdentityProviderService.${operation}`
});
/**
 * @internal
 */
const buildHttpRpcRequest = ({
  url
}, headers, body) => ({
  headers,
  url,
  body,
  method: 'POST'
});
export { buildHttpRpcRequest, cognitoUserPoolTransferHandler, defaultConfig, getSharedHeaders };
