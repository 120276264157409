import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Environment, environmentToken } from '@mca/shared/util';
import { MessageService } from 'primeng/api';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const errorsInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);
  const router = inject(Router);
  const authService = inject(AuthService);
  const env: Environment = inject(environmentToken);

  const handleRequestError = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 401: // Not Auth
      case 422: // Authtoken end
        if (req.url === env.apiUrl + 'auth') {
          showMessage(messageService, 'error', 'Invalid credentials');
          return throwError(() => error);
        }
        if (authService.isLoggedIn()) {
          authService.logout();
        }
        return EMPTY;

      case 409:
        showMessage(messageService, 'error', getErrorMessage(error));
        return throwError(() => error);

      case 503:
        showMessage(messageService, 'error', getErrorMessage(error));
        router.navigate(['maintenance']);
        return throwError(() => error);

      case 424:
        showMessage(messageService, 'info', getErrorMessage(error));
        return throwError(() => error);

      case 304: // Not modified
        return throwError(() => error);

      default:
        showMessage(messageService, 'error', getErrorMessage(error));
        return throwError(() => error);
    }
  };

  return next(req).pipe(
    catchError((e: any) => {
      if (e instanceof HttpErrorResponse) {
        return handleRequestError(e);
      }
      return throwError(() => e);
    }),
  );
};

const showMessage = (messageService: MessageService, severity: string, summary: string) => {
  messageService.add({ severity, summary, styleClass: 'pre-lined', life: 2000 + summary.length * 50 });
};

const getErrorMessage = (e?: HttpErrorResponse): string => {
  return e?.error?.text ?? 'Request error';
};
