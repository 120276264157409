const ptDict = {
  'Account recovery requires verified contact information': 'A recuperação da conta requer informações de contato verificadas',
  'Back to Sign In': 'Voltar para Entrar',
  'Change Password': 'Mudar senha',
  Changing: 'Mudando',
  Code: 'Código',
  'Confirm Password': 'Confirme a Senha',
  'Confirm Sign Up': 'Confirmar inscrição',
  'Confirm SMS Code': 'Confirme o código SMS',
  'Confirm TOTP Code': 'Confirme o código TOTP',
  Confirm: 'confirme',
  'Confirmation Code': 'Código de confirmação',
  Confirming: 'Confirmando',
  'Create a new account': 'Criar uma nova conta',
  'Create Account': 'Criar Conta',
  'Creating Account': 'Criando conta',
  'Dismiss alert': 'Descartar alerta',
  Email: 'O email',
  'Enter your code': 'Insira seu código',
  'Enter your Email': 'Digite seu e-mail',
  'Enter your phone number': 'Digite seu número de telefone',
  'Enter your username': 'entre com seu nome de usuário',
  'Forgot your password?': 'Esqueceu sua senha? ',
  'Hide password': 'Esconder a senha',
  'It may take a minute to arrive': 'Pode levar um minuto para chegar',
  Loading: 'Carregando',
  'New password': 'Nova Senha',
  or: 'ou',
  Password: 'Senha',
  'Phone Number': 'Número de telefone',
  'Resend Code': 'Reenviar código',
  'Reset your password': 'Redefina sua senha',
  'Reset your Password': 'Redefina sua senha',
  'Send code': 'Enviar código',
  'Send Code': 'Enviar código',
  Sending: 'Enviando',
  'Setup TOTP': 'Configurar TOTP',
  'Show password': 'Mostrar senha',
  'Sign in to your account': 'Faça login em sua conta',
  'Sign In with Amazon': 'Entrar com a Amazon',
  'Sign In with Apple': 'Entrar com a Apple',
  'Sign In with Facebook': 'Entrar com o Facebook',
  'Sign In with Google': 'Faça login no Google',
  'Sign in': 'Entrar',
  'Sign In': 'Entrar',
  'Signing in': 'Entrando',
  Skip: 'Pular',
  Submit: 'Enviar',
  Submitting: 'Enviando',
  Username: 'Nome do usuário',
  'Verify Contact': 'Verificar contato',
  Verify: 'Verificar',
  'We Emailed You': 'Enviamos um e-mail para você',
  'We Sent A Code': 'Enviamos um código',
  'We Texted You': 'Enviamos um SMS para você',
  'Your code is on the way. To log in, enter the code we emailed to': 'Seu código está a caminho. Para fazer login, insira o código para o qual enviamos um e-mail',
  'Your code is on the way. To log in, enter the code we sent you': 'Seu código está a caminho. Para fazer login, insira o código que enviamos para você',
  'Your code is on the way. To log in, enter the code we texted to': 'Seu código está a caminho. Para fazer login, insira o código para o qual enviamos uma mensagem de texto'
};
export { ptDict };