/* eslint-disable @typescript-eslint/naming-convention */
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { PageService } from '@mca/shared/domain';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const pageService = inject(PageService);

  pageService.loadingEvents$.next('http request');
  if (!req.reportProgress) {
    req = req.clone({
      setHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
  return authService.getStateDistinctValue('awsToken').pipe(
    take(1),
    switchMap(awsToken => {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${awsToken}`,
        },
      });
      return next(req);
    }),
  );
};
