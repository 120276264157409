import { MongoAddress } from '@mca/shared/domain';

export class Bankrec {
  address = new MongoAddress();
  name?: string;
  contact?: string;
  phone?: string;
  routing = '';
  account = '';
  accounttype = 1;
  id = 0;
  is_active = false;
  is_default = false;
  generateFee = false;
  description = '';
}

export enum CreditCardType {
  visa = 'Visa',
  mastercard = 'Mastercard',
  americanExpress = 'American Express',
  discover = 'Discover',
}

export interface CreditCard {
  id: number;
  is_active: boolean;
  is_default: boolean;
  name: string;
  type: CreditCardType;
  account: string;
  expiry_date: string;
  security_code: string | null;
  zip_code: string;
}
