import { assertOAuthConfig } from '@aws-amplify/core/internals/utils';
import { openAuthSession } from '../../../../utils/openAuthSession.mjs';
import { getRedirectUrl } from './getRedirectUrl.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const oAuthSignOutRedirect = async (authConfig, preferPrivateSession = false) => {
  assertOAuthConfig(authConfig);
  const {
    loginWith,
    userPoolClientId
  } = authConfig;
  const {
    domain,
    redirectSignOut
  } = loginWith.oauth;
  const signoutUri = getRedirectUrl(redirectSignOut);
  const oAuthLogoutEndpoint = `https://${domain}/logout?${Object.entries({
    client_id: userPoolClientId,
    logout_uri: encodeURIComponent(signoutUri)
  }).map(([k, v]) => `${k}=${v}`).join('&')}`;
  return openAuthSession(oAuthLogoutEndpoint);
};
export { oAuthSignOutRedirect };
