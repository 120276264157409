import { AuthError } from './AuthError.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const USER_UNAUTHENTICATED_EXCEPTION = 'UserUnAuthenticatedException';
const USER_ALREADY_AUTHENTICATED_EXCEPTION = 'UserAlreadyAuthenticatedException';
const DEVICE_METADATA_NOT_FOUND_EXCEPTION = 'DeviceMetadataNotFoundException';
const AUTO_SIGN_IN_EXCEPTION = 'AutoSignInException';
const INVALID_REDIRECT_EXCEPTION = 'InvalidRedirectException';
const invalidRedirectException = new AuthError({
  name: INVALID_REDIRECT_EXCEPTION,
  message: 'signInRedirect or signOutRedirect had an invalid format or was not found.',
  recoverySuggestion: 'Please make sure the signIn/Out redirect in your oauth config is valid.'
});
const INVALID_ORIGIN_EXCEPTION = 'InvalidOriginException';
const invalidOriginException = new AuthError({
  name: INVALID_ORIGIN_EXCEPTION,
  message: 'redirect is coming from a different origin. The oauth flow needs to be initiated from the same origin',
  recoverySuggestion: 'Please call signInWithRedirect from the same origin.'
});
const OAUTH_SIGNOUT_EXCEPTION = 'OAuthSignOutException';
const TOKEN_REFRESH_EXCEPTION = 'TokenRefreshException';
const UNEXPECTED_SIGN_IN_INTERRUPTION_EXCEPTION = 'UnexpectedSignInInterruptionException';
export { AUTO_SIGN_IN_EXCEPTION, DEVICE_METADATA_NOT_FOUND_EXCEPTION, INVALID_ORIGIN_EXCEPTION, INVALID_REDIRECT_EXCEPTION, OAUTH_SIGNOUT_EXCEPTION, TOKEN_REFRESH_EXCEPTION, UNEXPECTED_SIGN_IN_INTERRUPTION_EXCEPTION, USER_ALREADY_AUTHENTICATED_EXCEPTION, USER_UNAUTHENTICATED_EXCEPTION, invalidOriginException, invalidRedirectException };
